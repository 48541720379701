import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import vueDebounce from 'vue-debounce'
import { mapMutations } from 'vuex';
import { markdown, markdownInline } from './lib/markdown';
import { geometryAsString } from './lib/utils';

Vue.config.productionTip = false

Vue.use(vueDebounce);

Vue.filter('markdown', markdown);
Vue.filter('markdownInline', markdownInline);
Vue.filter('position', (str, item, opts) => 
  str
  .replace(/@POS(ITION)?@/g, geometryAsString(item, opts) || "(position unknown)")
  .replace(/@DMS@/g, geometryAsString(item, {...opts, dms:true}) || "(position unknown)")
);
// Vue.filter('position', (str, item, opts) => str.replace(/@POS(ITION)?@/, "☺"));

new Vue({
  data () {
    return {
      apiUrl: "/api",
      snack: false,
      snackText: null,
      snackColour: null,

      user: null,

      wsUrl: "/ws",
      ws: null
    }
  },

  methods: {

    markdown (value) {
      return typeof value == "string"
        ? marked(value)
        : value;
    },

    showSnack(text, colour = "primary") {
      console.log("showSnack", text, colour);
      this.snackColour = colour;
      this.snackText = text;
      this.snack = true;
    },

    initWs () {
      if (this.ws) {
        console.log("WebSocket initWs already called");
        return;
      }

      this.ws = new WebSocket(this.wsUrl);

      this.ws.addEventListener("message", (ev) => {
        const msg = JSON.parse(ev.data);
        this.setServerEvent(msg);
      });

      this.ws.addEventListener("open", (ev) => {
        console.log("WebSocket connection open", ev);
        this.setServerConnectionState(true);
      });

      this.ws.addEventListener("close", (ev) => {
        console.warn("WebSocket connection closed", ev);
        delete this.ws;
        this.ws = null;
        setTimeout( this.initWs, 5000 );
      });

      this.ws.addEventListener("error", (ev) => {
        console.error("WebSocket connection error", ev);
        this.ws.close();
        delete this.ws;
        this.ws = null;
        setTimeout( this.initWs, 60000 );
        this.setServerConnectionState(false);
      });

    },

    ...mapMutations(['setServerEvent', 'setServerConnectionState'])

  },

  created () {
    this.wsUrl = location.protocol == "https:"
      ? "wss://"+location.host+this.wsUrl
      : "ws://"+location.host+this.wsUrl;

    this.$nextTick( () =>  this.initWs() );
  },

  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
