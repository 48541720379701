import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Project from '../views/Project.vue'
import ProjectList from '../views/ProjectList.vue'
import ProjectSummary from '../views/ProjectSummary.vue'
import LineList from '../views/LineList.vue'
import Plan from '../views/Plan.vue'
import LineSummary from '../views/LineSummary.vue'
import SequenceList from '../views/SequenceList.vue'
import SequenceSummary from '../views/SequenceSummary.vue'
import Calendar from '../views/Calendar.vue'
import Log from '../views/Log.vue'
import QC from '../views/QC.vue'
import Graphs from '../views/Graphs.vue'
import Map from '../views/Map.vue'


Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/feed/:source',
    name: 'Feed',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Feed.vue')
  },
  {
    path: "/settings/equipment",
    name: "equipment",
    component: () => import(/* webpackChunkName: "about" */ '../views/Equipment.vue')
  },
  {
    pathToRegexpOptions: { strict: true },
    path: "/login",
    redirect: "/login/"
  },
  {
    pathToRegexpOptions: { strict: true },
    name: "Login",
    path: "/login/",
    component: Login,
    meta: {
//       breadcrumbs: [
//         { text: "Projects", href: "/projects", disabled: true }
//       ]
    }
  },
  {
//     pathToRegexpOptions: { strict: true },
    path: "/logout",
    component: Logout,
  },
  {
    pathToRegexpOptions: { strict: true },
    path: "/projects",
    redirect: "/projects/"
  },
  {
    pathToRegexpOptions: { strict: true },
    path: "/projects/",
    component: ProjectList,
    meta: {
      breadcrumbs: [
        { text: "Projects", href: "/projects", disabled: true }
      ]
    }
  },
  {
    pathToRegexpOptions: { strict: true },
    path: "/projects/:project",
    redirect: "/projects/:project/"
  },
  {
    pathToRegexpOptions: { strict: true },
    path: "/projects/:project/",
    name: "Project",
    component: Project,
    meta: {
      breadcrumbs: [
        { text: "Projects", href: "/projects" },
        {
          text: (ctx) => ctx.$store.state.project.projectName || "…",
          href: (ctx) => `/projects/${ctx.$store.state.project.projectId || ctx.$route.params.project || ""}/`
        }
      ]
    },
    children: [
      {
        path: "",
        redirect: "summary"
      },
      {
        path: "summary",
        component: ProjectSummary
      },
      {
        path: "lines/",
        name: "LineList",
        component: LineList
      },
      {
        path: "plan/",
        name: "Plan",
        component: Plan
      },
      {
        path: "lines/:line",
        name: "Line",
        component: LineSummary
      },
      {
        path: "sequences",
        component: SequenceList
      },
      {
        path: "sequences/:sequence",
        component: SequenceSummary
      },
      {
        path: "calendar",
        component: Calendar
      },
      {
        path: "log",
        component: Log,
        children: [
          { path: "sequence/:sequence", name: "logBySequence" },
          { path: "date/:date0", name: "logByDate" },
          { path: "date/:date0/:date1", name: "logByDates" }
        ]
      },
      {
        path: "qc",
        component: QC
      },
      {
        path: "graphs",
        component: Graphs,
        children: [
          { path: "sequence/:sequence", name: "graphsBySequence" },
          { path: "sequence/:sequence0/:sequence1", name: "graphsBySequences" },
          { path: "date/:date0", name: "graphsByDate" },
          { path: "date/:date0/:date1", name: "graphsByDates" }
        ]
      },
      {
        path: "map",
        name: "map",
        component: Map
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
