<template>
  <div>
  SequenceSummary
  </div>
</template>

<script>

export default {
  name: "SequenceSummary"
}

</script>
