<template>
<div class="line-status" v-if="sequences.length == 0">
  <slot name="empty"></slot>
</div>
<div class="line-status" v-else-if="sequenceHref">
  <router-link v-for="sequence in sequences" :key="sequence.sequence"
    class="sequence"
    :class="sequence.status"
    :style="style(sequence)"
    :title="title(sequence)"
    :to="sequenceHref(sequence)"
  >
  </router-link>
</div>
<div class="line-status" v-else>
  <div v-for="sequence in sequences"
    class="sequence"
    :class="sequence.status"
    :style="style(sequence)"
    :title="title(sequence)"
  >
  </div>
</div>
</template>

<style lang="stylus" scoped>
.line-status
  display flex
  flex-direction column
  height 67%
  min-width 64px
  min-height 16px
  background-color #d3d3d314
  border-radius 4px

  .sequence
    flex 1 1 auto
    opacity 0.5
    border-radius 4px

    &.ntbp
      background-color red
    &.raw
      background-color orange
    &.final
      background-color green
    &.online
      background-color blue
    &.planned
      background-color magenta
</style>

<script>

export default {
  name: 'DougalLineStatus',

  props: {
    preplot: Object,
    sequences: Array,
    "sequence-href": Function
  },

  methods: {
    style (s) {
      const values = {};
      const fsp = s.status == "final"
        ? s.fsp_final
        : s.status == "ntbp"
          ? (s.fsp_final || s.fsp)
          : s.fsp; /* status == "raw" */

      const lsp = s.status == "final"
        ? s.lsp_final
        : s.status == "ntbp"
          ? (s.lsp_final || s.lsp)
          : s.lsp; /* status == "raw" */

      const pp0 = Math.min(this.preplot.fsp, this.preplot.lsp);
      const pp1 = Math.max(this.preplot.fsp, this.preplot.lsp);
      const len = pp1-pp0;
      const sp0 = Math.max(Math.min(fsp, lsp), pp0);
      const sp1 = Math.min(Math.max(fsp, lsp), pp1);

      const left = (sp0-pp0)/len;
      const right = 1-((sp1-pp0)/len);

      values["margin-left"] = left*100 + "%";
      values["margin-right"] = right*100 + "%";

      return values;
    },

    title (s) {
      const status = s.status == "final"
        ? "Final"
        : s.status == "raw"
          ? "Acquired"
          : s.status == "ntbp"
            ? "NTBP"
            : s.status == "planned"
              ? "Planned"
              : s.status;

      const remarks = "\n"+[s.remarks, s.remarks_final].join("\n").trim()

      return `Sequence ${s.sequence} – ${status} (${s.fsp_final || s.fsp}−${s.lsp_final || s.lsp})${remarks}`;
    }
  }

}

</script>
