import { render, staticRenderFns } from "./graph-arrays-ij-scatter.vue?vue&type=template&id=864cfb38&scoped=true&"
import script from "./graph-arrays-ij-scatter.vue?vue&type=script&lang=js&"
export * from "./graph-arrays-ij-scatter.vue?vue&type=script&lang=js&"
import style0 from "./graph-arrays-ij-scatter.vue?vue&type=style&index=0&id=864cfb38&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "864cfb38",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCard,VCardTitle,VCol,VContainer,VOverlay,VProgressCircular,VRow,VSpacer,VSwitch})
