var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.pid",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('a',{attrs:{"href":("/projects/" + (item.pid))}},[_vm._v(_vm._s(value))])]}},{key:"item.shots",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total ? (item.prime + item.other) : "")+" ")]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total ? ((1 - (item.remaining / item.total))*100).toFixed(1)+"%" : "")+" "),(item.total)?_c('v-progress-linear',{attrs:{"height":"2","value":((1 - (item.remaining / item.total))*100)}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }