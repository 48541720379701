
function setProjectId (state, pid) {
  state.projectId = pid;
}

function setProjectName (state, name) {
  state.projectName = name;
}

function setProjectSchema (state, schema) {
  state.projectSchema = schema;
}

export default { setProjectId, setProjectName, setProjectSchema };
