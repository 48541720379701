<template>
  <div>
    <v-sheet height="64">
      <v-toolbar flat>

        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              color="grey darken-2"
              v-bind="attrs"
              v-on="on"
            >
              <span>Go to</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="setFirst">
              <v-list-item-title>First sequence</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setLast">
              <v-list-item-title>Last sequence</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setToday">
              <v-list-item-title>Today</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>


        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small>mdi-chevron-right</v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              color="grey darken-2"
              v-bind="attrs"
              v-on="on"
            >
              <span>{{ typeLabel }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Week</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = '4day'">
              <v-list-item-title>4 days</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet>
      <v-calendar
        ref="calendar"
        v-model="focus"
        :events="events"
        :event-color="getEventColour"
        color="primary"
        :type="type"
        :locale-first-day-of-year="4"
        :weekdays="weekdays"
        :show-week="true"
        @click:date="showLogForDate"
        @click:event="showLogForEvent"
      ></v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "Calendar",

  data () {
    return {
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      type: "week",
      focus: "",
      events: [
      ],
      options: {
        sortBy: "sequence"
      }
    };
  },

  computed: {
    typeLabel () {
      const labels = {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      };

      return labels[this.type];
    },

    ...mapGetters(['loading'])
  },

  methods: {

    async getEvents () {
      const query = new URLSearchParams(this.options);
      const url = `/project/${this.$route.params.project}/sequence?${query.toString()}`;

      const finalSequences = await this.api([url]) || [];
      this.events = finalSequences.map(s => {
        const e = {};
        //e.start = s.ts0.substring(0,10)+" "+s.ts0.substring(11,19)
        //e.end = s.ts1.substring(0,10)+" "+s.ts1.substring(11,19)
        e.routerLink = { name: "logBySequence", params: { sequence: s.sequence } };
        e.start = new Date(s.ts0);
        e.end = new Date(s.ts1);
        e.timed = true;
        e.colour = "orange";
        e.name = `Sequence ${s.sequence}`;
        return e;
      });
    },

    getEventColour (event) {
      return event.colour;
    },

    setToday () {
      this.focus = "";
    },

    setFirst () {
      this.focus = this.events[this.events.length-1].start;
    },

    setLast () {
      this.focus = this.events[0].start;
    },

    prev () {
      this.$refs.calendar.prev()
    },

    next () {
      this.$refs.calendar.next()
    },

    showLogForDate ({ date }) {
      this.$router.push({name: "logByDate", params: {date0: date}});
    },

    showLogForEvent ({event}) {
      if (event.routerLink) {
        this.$router.push(event.routerLink);
      }
    },


    ...mapActions(["api"])

  },

  async mounted () {
    await this.getEvents();
    if (this.events.length) {
      this.setLast();
    }
  }
}

</script>
