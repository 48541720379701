
function user (state) {
  return state.user;
}

function writeaccess (state) {
  return state.user && ["user", "admin"].includes(state.user.role);
}

function adminaccess (state) {
  return state.user && state.user.role == "admin";
}

function preferences (state) {
  return state.preferences;
}

export default { user, writeaccess, adminaccess, preferences };
