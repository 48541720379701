import Vue from 'vue'
import Vuex from 'vuex'

import api from './modules/api'
import user from './modules/user'
import snack from './modules/snack'
import project from './modules/project'
import notify from './modules/notify'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    api,
    user,
    snack,
    project,
    notify
  }
})
