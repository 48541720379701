<template>
  <v-container fluid fill-height class="ma-0 pa-0">
    <v-row no-gutters align="stretch" class="fill-height">
      <v-col cols="12">
        <!-- Show component here according to selected route -->
        <keep-alive>
          <router-view :key="$route.path"></router-view>
        </keep-alive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Project',
  components: {
  },
  data () {
    return {
    //
    }
  },

  methods: {
    ...mapActions(["getProject"])
  },

  async mounted () {
    await this.getProject(this.$route.params.project);
  }

}
</script>
